<template>
  <v-navigation-drawer v-model="drawer" class="sidebar" fixed right hide-overlay app width="320">
    <div class="content">
      <header class="drawer-header">
        <typography v-if="$vuetify.breakpoint.mdAndUp" type="h5" light>You've told us...</typography>
      </header>
      <expansion-panel v-model="openDrawerPanelIndex">
        <expansion-panel-content
          :expand-icon="openDrawerPanelIndex === associationLevelIndex ? 'remove' : 'add'"
        >
          <div slot="header">Reward level</div>
          <sidebar-item :to-id="accumulationLevelId" label="Reward Level">
            <span name="SideBarRewardLevel">{{ rewardLevelLabels[accumulationLevel] }}</span>
          </sidebar-item>
          <sidebar-item
            v-if="accumulationLevel === costCentreLevel"
            :to-id="costCentresId"
            label="Has cost centres"
          >
            <span name="SideBarHasCostCentres">{{ hasCostCentresLabel }}</span>
          </sidebar-item>
        </expansion-panel-content>

        <expansion-panel-content
          :disabled="!associationLevelSectionComplete"
          :expand-icon="openDrawerPanelIndex === accountNumberIndex ? 'remove' : 'add'"
        >
          <div slot="header">Account number</div>
          <sidebar-item :to-id="accountNumberId" label="Account number">
            <span name="SideBarAccountNumber">{{ accountNumber }}</span>
          </sidebar-item>
        </expansion-panel-content>

        <expansion-panel-content
          :expand-icon="openDrawerPanelIndex === loyaltyDetailsIndex ? 'remove' : 'add'"
          :disabled="!accountNumberSectionComplete"
        >
          <div slot="header">Loyalty details</div>
          <div
            :key="index"
            v-for="(associationDetail, index) in associationDetails"
            v-bind:class="{'has-multiple-association-details': associationDetails.length > 1}"
          >
            <sidebar-item
              :key="`costCentresNumber${index}`"
              :to-id="loyaltyTypeId + index"
              v-if="hasCostCentres === true && associationDetail.loyaltyScheme"
              :label="`Cost centre ${associationDetails.length > 1 ? '#' + (index + 1) : ''}`"
              v-on:click="onEditCostCentreDetails(index)"
            >
              <span :name="`SideBarCostCentre${index}`">{{ associationDetail.costCentreCode }}</span>
            </sidebar-item>

            <sidebar-item
              :name="`SideBarFlyBuys${index}`"
              :key="`flyBuys${index}`"
              :to-id="loyaltyTypeId + index"
              label="Fly Buys number"
              v-on:click="onEditCostCentreDetails(index)"
              v-if="associationDetail.loyaltyScheme === flyBuys"
            >
              <span :name="`SideBarFlyBuys${index}`">{{ associationDetail.flyBuysNumber }}</span>
            </sidebar-item>

            <sidebar-item
              :key="`airpointsNumber${index}`"
              :to-id="loyaltyTypeId + index"
              :label="`${airpoints} number`"
              v-on:click="onEditCostCentreDetails(index)"
              v-if="associationDetail.loyaltyScheme === airpoints"
            >
              <span :name="`SideBarAirpoints${index}`">{{ associationDetail.airpointsNumber }}</span>
            </sidebar-item>
          </div>
        </expansion-panel-content>

        <expansion-panel-content
          :disabled="!loyaltyDetailsSectionComplete"
          :expand-icon="openDrawerPanelIndex === termsAndConditionsIndex ? 'remove' : 'add'"
        >
          <div slot="header">Terms &amp; conditions</div>
          <sidebar-item label="Have authority" :to-id="termsAndConditionsId">
            <span name="SideBarHasAuthority">{{ hasAuthority ? 'Yes' : 'No' }}</span>
          </sidebar-item>
          <sidebar-item label="Accepted terms" :to-id="termsAndConditionsId">
            <span name="SideBarAcceptedTerms">{{ acceptedTerms ? 'Yes' : 'No' }}</span>
          </sidebar-item>
        </expansion-panel-content>
      </expansion-panel>

      <!-- TODO: Dont have any help content so leaving out for now 
        
        <tooltip class="help-tooltip" top>
        <div slot="activator" class="help">
          <icon-button>
            <help-icon/>
          </icon-button>
          <typography type="small-p" class="help__text" no-margin>Need help?</typography>
        </div>
        <typography type="small-p" class="help-tooltip__content" no-margin>Help content</typography>
      </tooltip>-->
    </div>
  </v-navigation-drawer>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import SidebarItem from '@/components/molecules/SidebarItem.vue';
import ExpansionPanel from '@/components/atoms/ExpansionPanel.vue';
import ExpansionPanelContent from '@/components/atoms/ExpansionPanelContent.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';
import TextButton from '@/components/atoms/TextButton.vue';
import IconButton from '@/components/atoms/IconButton.vue';
import HelpIcon from '@/assets/icons/help-white.svg';
import {
  SECTION_ASSOCIATION_LEVEL,
  SECTION_ACCOUNT_NUMBER,
  SECTION_LOYALTY_DETAILS,
  SECTION_TERMS_AND_CONDITIONS,
  FIELD_ASSOCIATION_REWARD_LEVEL,
  FIELD_ASSOCIATION_COST_CENTRES,
  FIELD_ACCOUNT_NUMBER,
  FIELD_LOYALTY_TYPE,
  FIELD_LOYALTY_TYPE_FLYBUYS,
  FIELD_LOYALTY_TYPE_AIRPOINTS,
  FIELD_TERMS_AND_CONDITIONS
} from '@/constants/html-ids';
import {
  ASSOCIATION_LEVEL_INDEX,
  ACCOUNT_NUMBER_INDEX,
  LOYALTY_DETAILS_INDEX,
  TERMS_AND_CONDITIONS_INDEX
} from '@/constants/ui';
import { REWARD_LEVEL_LABELS, COST_CENTRE_LEVEL } from '@/constants/form';
import { FLYBUYS, AIRPOINTS } from '@/constants/form';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    Typography,
    TextButton,
    Tooltip,
    IconButton,
    HelpIcon,
    SidebarItem,
    ExpansionPanel,
    ExpansionPanelContent
  },
  computed: {
    hasCostCentresLabel() {
      return this.hasCostCentres == null
        ? ''
        : this.hasCostCentres
          ? 'Yes'
          : 'No';
    },
    drawer: {
      get() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return true;
        }

        return this.$store.state.ui.drawerOpen;
      },
      set(isOpen) {
        this.$store.dispatch('ui/toggleDrawer', isOpen);
      }
    },
    openDrawerPanelIndex: {
      get() {
        return this.$store.state.ui.openDrawerPanelIndex;
      },
      set(value) {
        this.$store.dispatch('ui/changeDrawerPanelIndex', value);
      }
    },
    ...mapState({
      accumulationLevel: state => state.associationLevel.accumulationLevel,
      hasCostCentres: state => state.associationLevel.hasCostCentres,
      associationLevelSectionComplete: state =>
        state.associationLevel.sectionComplete,
      accountNumber: state => state.accountNumber.accountNumber,
      accountNumberSectionComplete: state =>
        state.accountNumber.sectionComplete,
      associationDetails: state => state.loyaltyDetails.associationDetails,
      loyaltyDetailsSectionComplete: state =>
        state.loyaltyDetails.sectionComplete,
      hasAuthority: state => state.termsAndConditions.hasAuthority,
      acceptedTerms: state => state.termsAndConditions.hasReadTermsAndConditions
    }),
    ...mapGetters({
      currentSection: 'navigation/currentSection'
    }),
    associationLevelIndex: () => ASSOCIATION_LEVEL_INDEX,
    accumulationLevelId: () => FIELD_ASSOCIATION_REWARD_LEVEL,
    costCentreLevel: () => COST_CENTRE_LEVEL,
    costCentresId: () => FIELD_ASSOCIATION_COST_CENTRES,
    rewardLevelLabels: () => REWARD_LEVEL_LABELS,
    accountNumberIndex: () => ACCOUNT_NUMBER_INDEX,
    accountNumberId: () => FIELD_ACCOUNT_NUMBER,
    loyaltyDetailsIndex: () => LOYALTY_DETAILS_INDEX,
    loyaltyTypeId: () => FIELD_LOYALTY_TYPE,
    loyaltyTypeFlybuysId: () => FIELD_LOYALTY_TYPE_FLYBUYS,
    loyaltyTypeAirpointsId: () => FIELD_LOYALTY_TYPE_AIRPOINTS,
    flyBuys: () => FLYBUYS,
    airpoints: () => AIRPOINTS,
    termsAndConditionsIndex: () => TERMS_AND_CONDITIONS_INDEX,
    termsAndConditionsId: () => FIELD_TERMS_AND_CONDITIONS
  },
  watch: {
    currentSection(newVal) {
      switch (newVal) {
        case SECTION_ASSOCIATION_LEVEL:
          this.$store.dispatch(
            'ui/changeDrawerPanelIndex',
            ASSOCIATION_LEVEL_INDEX
          );
          break;
        case SECTION_ACCOUNT_NUMBER:
          this.$store.dispatch(
            'ui/changeDrawerPanelIndex',
            ACCOUNT_NUMBER_INDEX
          );
          break;
        case SECTION_LOYALTY_DETAILS:
          this.$store.dispatch(
            'ui/changeDrawerPanelIndex',
            LOYALTY_DETAILS_INDEX
          );
          break;
        case SECTION_TERMS_AND_CONDITIONS:
          this.$store.dispatch(
            'ui/changeDrawerPanelIndex',
            TERMS_AND_CONDITIONS_INDEX
          );
          break;
        default:
          break;
      }
    }
  },
  methods: {
    onEditCostCentreDetails(index) {
      this.$store.dispatch('loyaltyDetails/setEditIndex', index);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.sidebar {
  color: $white;
  max-width: 100%;
  background-image: linear-gradient(-180deg, #f7801d 0%, #f76b1c 100%);
  padding: rem(20px 25px);

  @include md {
    padding: rem(30px 55px);
  }
}

.drawer-header {
  text-align: center;
  margin-bottom: rem(20px);

  @include md {
    margin-bottom: 0;
    text-align: left;
  }
}

.content {
  position: relative;
  padding-bottom: rem(80px);
  min-height: 100%;

  .help {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__text {
      margin-left: rem(10px);
    }
  }
}

.help-tooltip {
  position: absolute;
  left: 0;
  bottom: 0;

  &__content {
    color: $charcoal;
    width: rem(270px);
  }
}

.has-multiple-association-details {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
</style>

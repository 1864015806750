import cloneDeep from 'lodash.clonedeep';
import api from '@/api';
import { AIRPOINTS, FLYBUYS } from '@/constants/form';

const DEFAULT_ASSOCIATION_DETAILS = {
  inputIsValid: false,
  isValidating: false,
  apiIsValid: null,
  loyaltyScheme: null,
  costCentreCode: null,
  flyBuysNumber: null,
  airpointsNumber: null,
  airpointsFirstName: null,
  airpointsLastName: null
};

export const state = () => ({
  editIndex: null,
  sectionComplete: false,
  associationDetails: [cloneDeep(DEFAULT_ASSOCIATION_DETAILS)]
});

export const mutations = {
  changeLoyaltyScheme(state, { index, value }) {
    state.associationDetails[index].loyaltyScheme = value;
  },
  changeFlyBuysNumber(state, { index, value }) {
    state.associationDetails[index].flyBuysNumber = value;
  },
  changeCostCentreCode(state, { index, value }) {
    state.associationDetails[index].costCentreCode = value;
  },
  changeAirpointsNumber(state, { index, value }) {
    state.associationDetails[index].airpointsNumber = value;
  },
  changeAirpointsFirstName(state, { index, value }) {
    state.associationDetails[index].airpointsFirstName = value;
  },
  changeAirpointsLastName(state, { index, value }) {
    state.associationDetails[index].airpointsLastName = value;
  },
  resetLoyaltyScheme(state) {
    state.associationDetails = [cloneDeep(DEFAULT_ASSOCIATION_DETAILS)];
  },
  resetLoyaltySchemeByIndex(state, index) {
    state.associationDetails.splice(
      index,
      1,
      cloneDeep(DEFAULT_ASSOCIATION_DETAILS)
    );
  },
  addCostCentreDetails(state) {
    state.associationDetails.push(cloneDeep(DEFAULT_ASSOCIATION_DETAILS));
  },
  removeCostCentreDetails(state) {
    state.associationDetails.splice(state.associationDetails.length - 1, 1);
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  },
  changeInputIsValidByIndex(state, { index, value }) {
    state.associationDetails[index].inputIsValid = value;
  },
  changeIsValidatingByIndex(state, { index, value }) {
    state.associationDetails[index].isValidating = value;
  },
  changeApiIsValidByIndex(state, { index, value }) {
    state.associationDetails[index].apiIsValid = value;
  },
  setEditIndex(state, value) {
    state.editIndex = value;
  },
  removeAssociationDetails(state, index) {
    state.associationDetails.splice(index, 1);
  }
};

export const actions = {
  changeLoyaltyScheme({ commit }, { index, value }) {
    commit('changeLoyaltyScheme', { index, value });
  },
  changeFlyBuysNumber({ commit }, { index, value }) {
    commit('changeFlyBuysNumber', { index, value });
  },
  changeCostCentreCode({ commit }, { index, value }) {
    commit('changeCostCentreCode', { index, value });
  },
  changeAirpointsNumber({ commit }, { index, value }) {
    commit('changeAirpointsNumber', { index, value });
  },
  changeAirpointsFirstName({ commit }, { index, value }) {
    commit('changeAirpointsFirstName', { index, value });
  },
  changeAirpointsLastName({ commit }, { index, value }) {
    commit('changeAirpointsLastName', { index, value });
  },
  resetLoyaltyScheme({ commit }) {
    commit('resetLoyaltyScheme');
  },
  resetLoyaltySchemeByIndex({ commit }, index) {
    commit('resetLoyaltySchemeByIndex', index);
  },
  addCostCentreDetails({ commit }) {
    commit('addCostCentreDetails');
  },
  removeCostCentreDetails({ commit }) {
    commit('removeCostCentreDetails');
  },
  changeSectionComplete({ commit }, value) {
    commit('changeSectionComplete', value);
  },
  changeInputIsValidByIndex({ commit }, { index, value }) {
    commit('changeInputIsValidByIndex', { index, value });
  },
  removeAssociationDetails({ commit }, index) {
    commit('removeAssociationDetails', index);
  },
  changeApiIsValidByIndex({ commit }, { index, value }) {
    commit('changeApiIsValidByIndex', { index, value });
  },
  setEditIndex({ commit }, value) {
    commit('setEditIndex', value);
  },
  async validateFlyBuys({ commit }, { index, flyBuysNumber }) {
    commit('changeIsValidatingByIndex', { index, value: true });
    let success;
    try {
      await api.validateFlyBuys(flyBuysNumber);
      success = true;
    } catch (error) {
      success = false;
    }
    commit('changeIsValidatingByIndex', { index, value: false });
    commit('changeApiIsValidByIndex', { index, value: success });

    return success;
  },
  async validateAirpoints(
    { commit },
    { index, airpointsNumber, airpointsFirstName, airpointsLastName }
  ) {
    commit('changeIsValidatingByIndex', { index, value: true });
    let success;
    try {
      await api.validateAirpoints(
        airpointsNumber,
        airpointsFirstName,
        airpointsLastName
      );
      success = true;
    } catch (error) {
      success = false;
    }
    commit('changeIsValidatingByIndex', { index, value: false });
    commit('changeApiIsValidByIndex', { index, value: success });

    return success;
  }
};

export const getters = {
  associationDetailsIsValid: state => {
    return state.associationDetails
      .map(v => v.inputIsValid && v.apiIsValid)
      .every(v => v === true);
  },
  hasAirpointsLoyaltyScheme: state => {
    return state.associationDetails.some(v => v.loyaltyScheme === AIRPOINTS);
  },
  hasFlyBuysLoyaltyScheme: state => {
    return state.associationDetails.some(v => v.loyaltyScheme === FLYBUYS);
  }
};

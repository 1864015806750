import { render, staticRenderFns } from "./FormSummarySidebar.vue?vue&type=template&id=ec07437e&scoped=true&"
import script from "./FormSummarySidebar.vue?vue&type=script&lang=js&"
export * from "./FormSummarySidebar.vue?vue&type=script&lang=js&"
import style0 from "./FormSummarySidebar.vue?vue&type=style&index=0&id=ec07437e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec07437e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib'
installComponents(component, {
  VNavigationDrawer,
})
